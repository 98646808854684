import Link from '@mui/material/Link'
import ErrorPageContainer from './ErrorPageContainer'
import Box from '@mui/material/Box'
import useTrackEvent from '../../custom-hooks/useTrackEvent'
import { EventTypes } from '../../types/enums/TrackEventType'

const GenericError = () => {
  useTrackEvent(EventTypes.Errors.UNEXPECTED_ERROR)

  return (
    <ErrorPageContainer title="Oops... something went wrong.">
      <Box data-cy="contact-details">
        Sorry for the inconvenience. Please <Link href="mailto:customersupport@kriya.co">contact us</Link> if you need
        any support.
      </Box>
    </ErrorPageContainer>
  )
}

export default GenericError
