import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import EligibilityQuestionWrapper from '../../components/EligibilityQuestionWrapper'
import { useForm } from 'react-hook-form'
import { StyledButtonsContainer } from '../../components/layout/Container'
import { OnboardingJourney } from '../../types/OnboardingJourney'
import PageLoading from '../../components/loading/PageLoading'
import { useFetchEligibilityQuestions } from '../../custom-hooks/useFetchEligibilityQuestions'
import { Navigate } from 'react-router-dom'
import routes from '../../routes'
import ReturnToMarketplaceDialog from '../../components/returnToMarketplace'
import Typography from '@mui/material/Typography'
import theme from '../../assets/theme'
import { Content } from '../../assets/styles/ContentStyles'
import { useCheckEligibilityAnswers } from '../../custom-hooks/useCheckEligibilityAnswers'
import { convertToBoolean } from '../../utils/stringToBooleanConverter'
import AgreementDialog from './Agreement'
import useTrackVirtualPageView from '../../custom-hooks/useVirtualPageView'
import { VirtualPage } from '../../types/enums/VirtualPage'

interface ManualEligibilityParams {
  onboardingJourney: OnboardingJourney
  setDisplayHeaderContent?: (shouldDisplay: boolean) => void
}

const ManualEligibility = ({ onboardingJourney, setDisplayHeaderContent }: ManualEligibilityParams) => {
  useTrackVirtualPageView(VirtualPage.MANUAL_ELIGIBILITY)
  const { company } = onboardingJourney

  const form = useForm<Record<string, string>>({
    mode: 'onChange'
  })

  const { register, handleSubmit } = form

  const { mutate: checkEligibilityAnswers, isLoading: isMutationLoading } = useCheckEligibilityAnswers({})

  const { data, isLoading } = useFetchEligibilityQuestions()

  const onSubmit = (formValues: Record<string, string>) => {
    if (!isMutationLoading) {
      checkEligibilityAnswers({
        companyId: company?.kriyaCompanyIdentifier,
        answers: Object.entries(formValues).map(([k, v]) => ({
          id: k,
          answer: convertToBoolean(v)
        }))
      })
    }
  }

  useEffect(() => {
    if (setDisplayHeaderContent) {
      setDisplayHeaderContent(false)
    }
  }, [setDisplayHeaderContent])

  if (isLoading) return <PageLoading title="Loading..." />
  if (data === undefined) {
    return <Navigate to={routes.error} />
  }

  return (
    <Box>
      <Content
        sx={{
          marginBottom: theme.spacing(16)
        }}
      >
        <Typography variant="h2" data-cy="title">
          Please answer the following questions about your organisation.
        </Typography>
      </Content>
      <form onSubmit={handleSubmit(onSubmit)} id="manual-eligibility-form">
        <Box>
          <EligibilityQuestionWrapper
            questions={data.manualEligibilityQuestions}
            register={register}
          ></EligibilityQuestionWrapper>
          <Box
            sx={{
              paddingTop: theme.spacing(32)
            }}
          >
            <Typography
              variant="helperText2"
              sx={{
                marginBottom: theme.spacing(16),
                color: theme.palette.common.textGrey900
              }}
            >
              By clicking "Continue", you agree to Kriya using your details to assess your eligibility for credit which
              may include soft checks at credit bureaus.
            </Typography>
          </Box>
          <StyledButtonsContainer
            sx={{
              marginTop: theme.spacing(16),
              paddingBottom: theme.spacing(12)
            }}
          >
            <AgreementDialog
              disabled={!form.formState.isValid || isMutationLoading}
              loading={isMutationLoading}
              onAcknowledgeAgreementHandler={() => onSubmit(form.getValues())}
            />
            {onboardingJourney.abandonedUrl && (
              <ReturnToMarketplaceDialog
                marketplace={onboardingJourney.merchant.name}
                disabled={isMutationLoading}
                redirectUrl={onboardingJourney.abandonedUrl}
              ></ReturnToMarketplaceDialog>
            )}
          </StyledButtonsContainer>
        </Box>
      </form>
    </Box>
  )
}

export default ManualEligibility
