import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ErrorPageContainer, { StyledErrorPageButtonContainer } from './ErrorPageContainer'
import PageLoading from '../../components/loading/PageLoading'
import { useOnboardingJourneyInfo } from '../../custom-hooks/useOnboardingJourneyQuery'
import { useParams } from 'react-router-dom'
import useTrackEvent from '../../custom-hooks/useTrackEvent'
import { EventTypes } from '../../types/enums/TrackEventType'

const EligibilityRejected = () => {
  useTrackEvent(EventTypes.Eligibility.RISK_REJECTED)

  const { merchantId, merchantCompanyIdentifier } = useParams()
  const { data, dataLoading } = useOnboardingJourneyInfo(merchantId, merchantCompanyIdentifier)

  if (dataLoading) return <PageLoading title="Loading..." />

  const handleReturnClick = () => {
    window.location.replace(data?.ineligibleUrl as string)
  }

  return (
    <ErrorPageContainer title="We're sorry">
      <Box data-cy="message">Your organisation didn't meet our eligibility criteria.</Box>
      <br />
      <Box data-cy="recommendations">
        If you are completing the checkout for an order, please go back to {data?.merchant.name} and use a different
        payment method to finalise your purchase.
      </Box>

      <StyledErrorPageButtonContainer>
        <Button variant="contained" onClick={handleReturnClick} id="return-to-merchant-button">
          Return to {data?.merchant.name}
        </Button>
      </StyledErrorPageButtonContainer>
    </ErrorPageContainer>
  )
}

export default EligibilityRejected
