export enum VirtualPage {
  COMPANY_SELECTION = 'Onboarding - Company Information',
  ELIGIBILITY = 'Onboarding - Eligibility',
  SOLE_TRADER_DETAILS = 'Onboarding - Sole Trader Details',
  MANUAL_ELIGIBILITY = 'Onboarding - Manual Eligibility',
  SOLE_TRADER_ELIGIBLE = 'Onboarding - Sole Trader Eligible',
  DIRECTOR_SELECTION = 'Onboarding - Limited Company Director Selection',
  DIRECTOR_SELECTED = 'Onboarding - Limited Company Director Selected',
  DIRECTOR_START = 'Onboarding - Limited Company Director Start',
  SELFIE_CHECK = 'Onboarding - Selfie Check',
  CHECKS_PASSED = 'Onboarding - Checks Passed'
}
