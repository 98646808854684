export const EventTypes = {
  Eligibility: {
    RISK_REVIEW: 'onboarding_risk_review',
    RISK_REJECTED: 'onboarding_risk_rejected',
    RISK_DECISION_RETRY: 'onboarding_risk_decision_retry',
    RISK_DECISION_DELAYED: 'onboarding_risk_decision_delayed'
  },
  CompanyInformation: {
    CLICK_COMPANY_TYPE: 'onboarding_click_company_type',
    CLICK_COMPANY_SELECTION_CONTINUE: 'onboarding_click_company_selection_continue',
    CLICK_SOLE_TRADER_IDENTIFICATION: 'onboarding_click_sole_trader_identification'
  },
  EligibilityPolling: {
    CLICK_RETRY: 'onboarding_click_risk_decision_retry'
  },
  SoleTrader: {
    Details: {
      CLICK_MANUAL_ADDRESS_INPUT: 'onboarding_click_sole_trader_details_manual_address_input',
      CLICK_CONTINUE: 'onboarding_click_sole_trader_details_continue'
    },
    ManualEligibility: {
      CLICK_CONTINUE: 'onboarding_click_sole_trader_manual_eligibility_continue',
      CLICK_DECLARATION_CLOSE: 'onboarding_click_sole_trader_declaration_close',
      CLICK_DECLARATION_ACKNOWLEDGE: 'onboarding_click_sole_trader_declaration_acknowledge'
    }
  },
  LimitedCompany: {
    DirectorSelection: {
      CLICK_CONTINUE: 'onboarding_click_director_selection_continue'
    },
    DirectorSelected: {
      CLICK_COPY_DIRECTOR_LINK: 'onboarding_click_director_selected_copy_director_link',
      CLICK_START: 'onboarding_click_director_selected_start',
      CLICK_NAVIGATE_BACK_TO_MERCHANT: 'onboarding_click_director_selected_navigate_back_to_merchant',
      CLICK_CONTINUE: 'onboarding_click_director_selected_continue'
    },
    DirectorStart: {
      CLICK_CONTINUE: 'onboarding_click_director_start_continue'
    }
  },
  Checks: {
    SignedTerms: {
      CLICK_OPEN: 'onboarding_click_terms_and_conditions_open',
      CLICK_CLOSE: 'onboarding_click_terms_and_conditions_close',
      CLICK_CONFIRM: 'onboarding_click_terms_and_conditions_confirm'
    }
  },
  OnboardingOutcomes: {
    Passed: {
      CLICK_NAVIGATE_BACK_TO_MERCHANT: 'onboarding_click_checks_passed_navigate_back_to_merchant',
      CLICK_DIRECT_DEBIT_LINK: 'onboarding_checks_passed_click_direct_debit_link'
    },
    ManualReview: {
      PAGE_DISPLAYED: 'onboarding_checks_review'
    },
    Failed: {
      PAGE_DISPLAYED: 'onboarding_checks_failed'
    },
    Polling: {
      DECISION_DELAYED: 'onboarding_effect_polling_decision_delayed'
    }
  },
  Experiments: {
    EXPOSE_COMPANY_SELECTION_EDUCATION: 'onboarding_expose_company_selection_education'
  },
  Errors: {
    INVALID_LINK: 'onboarding_invalid_link',
    SOLE_TRADER_CONFLICT: 'onboarding_sole_trader_conflict',
    UNEXPECTED_ERROR: 'onboarding_unexpected_error'
  },
  Shared: {
    ReturnToMerchant: {
      CLICK_CONFIRM: 'onboarding_click_return_to_merchant_confirm',
      CLICK_CANCEL: 'onboarding_click_return_to_merchant_cancel',
      CLICK_OPEN: 'onboarding_click_return_to_merchant'
    },
    PageLoading: {
      CLICK_NAVIGATE_BACK_TO_MERCHANT: 'onboarding_click_page_loading_navigate_back_to_merchant'
    }
  }
}
