import SegmentWrapper from '../utils/metrics/segmentWindow'
import { OnboardingJourney } from '../types/OnboardingJourney'
import { useEffect } from 'react'

const useIdentify = (data: OnboardingJourney | undefined) => {
  useEffect(() => {
    if (data === undefined) return
    SegmentWrapper.identify(data.id, {
      isEmployee: localStorage.getItem('admin_access_token') !== null,
      merchant: data.merchant?.name || 'Unknown',
      buyerId: data.company?.kriyaCompanyIdentifier,
      email: data.requester?.email,
      name: data.requester?.email
    })
  }, [data])
}

export default useIdentify
