import React, { useEffect, useMemo, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { Address } from '../../types/Address'
import FormLabel from '@mui/material/FormLabel'
import Autocomplete from '@mui/material/Autocomplete'
import { useFetchAddressByPostCode } from '../../custom-hooks/useFetchAddressInfo'
import { AddressSearchResponseItem } from '../../types/AddressSearchResponseItem'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import SearchIcon from '@mui/icons-material/Search'
import Button from '@mui/material/Button'
import hash from 'object-hash'
import ErrorText from '../../pages/error/FormErrors'
import useTrackEvent from '../../custom-hooks/useTrackEvent'
import { EventTypes } from '../../types/enums/TrackEventType'

type AddressSearchProps = {
  form: UseFormReturn<Address>
  onUseManualInputClick: () => void
}

const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: AddressSearchResponseItem) => {
  return (
    <Box component="li" {...props} key={hash(option)}>
      <Typography variant="inputText">{option.Label}</Typography>
    </Box>
  )
}

const validatePostCode = (value: string) => {
  const regex = new RegExp(
    '^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$',
    'i'
  )
  return regex.test(value)
}

const AddressSearch = ({ form, onUseManualInputClick }: AddressSearchProps) => {
  const trackEvent = useTrackEvent()

  const {
    setValue,
    formState: { errors },
    clearErrors,
    trigger
  } = form

  const [postCodeInputValue, setPostCodeInputValue] = useState<string>('')
  const isPostcodeValid = useMemo(
    () => !postCodeInputValue || validatePostCode(postCodeInputValue),
    [postCodeInputValue]
  )
  const [houseNumber, setHouseNumber] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { isLoading, data } = useFetchAddressByPostCode(
    postCodeInputValue,
    houseNumber,
    100,
    isPostcodeValid && !!postCodeInputValue
  )

  useEffect(() => {
    if (!isPostcodeValid) {
      setErrorMessage('Invalid Postcode')
    } else {
      setErrorMessage('')
    }
  }, [isPostcodeValid, setErrorMessage])

  const setAddressValues = (address?: AddressSearchResponseItem) => {
    setValue('addressLine1', address?.Line1 || '')
    setValue('addressLine2', address?.Line2 || '')
    setValue('city', address?.TownOrCity || '')
    setValue('postCode', address?.Postcode || '')
    setValue('region', address?.County || address?.Locality || '')
  }

  const handleManualAddressInputClick = () => {
    trackEvent(EventTypes.SoleTrader.Details.CLICK_MANUAL_ADDRESS_INPUT)
    onUseManualInputClick()
  }

  return (
    <>
      <FormControl>
        <FormLabel htmlFor="address-search-house-number">House or flat number (optional)</FormLabel>
        <TextField
          name="address-search-house-number"
          data-cy="address-search-house-number"
          value={houseNumber}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setHouseNumber(event.target.value)
          }}
        />
      </FormControl>
      <Box>
        <FormControl sx={{ width: '100%' }}>
          <FormLabel htmlFor="address-search-autocomplete">Postcode</FormLabel>
          <Autocomplete
            id="address-search-autocomplete"
            data-cy="address-search-autocomplete"
            freeSolo={true}
            filterOptions={(options) => options}
            onChange={async (_event, value, reason) => {
              if (reason !== 'selectOption') {
                setAddressValues()
                return
              }
              if ((value as AddressSearchResponseItem) !== undefined) {
                const address = value as AddressSearchResponseItem
                setAddressValues(address)
                await trigger(['addressLine1', 'city', 'postCode'])
                onUseManualInputClick()
              }
            }}
            options={data || []}
            loading={isLoading}
            renderInput={(params) => (
              <TextField
                placeholder="Enter postcode"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <SearchIcon />,
                  endAdornment: (
                    <>
                      {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
            getOptionLabel={(option) =>
              (option as AddressSearchResponseItem) !== undefined
                ? (option as AddressSearchResponseItem).Label || ''
                : (option as string)
            }
            onInputChange={(_event, value, reason) => {
              clearErrors('postCode')
              if (reason === 'reset') {
                setAddressValues()
                return
              }
              setPostCodeInputValue(value)
            }}
            renderOption={renderOption}
          />
        </FormControl>

        {errorMessage && <ErrorText id={'postcode'}>{errorMessage}</ErrorText>}
        {errors?.postCode && errors?.postCode.type === 'required' && (
          <ErrorText id={'postcode'}>Please enter post code</ErrorText>
        )}
      </Box>
      <Button variant="component" onClick={handleManualAddressInputClick} data-cy="enter-address-manually">
        Enter address manually here
      </Button>
    </>
  )
}

export default AddressSearch
